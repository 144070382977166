import React, { useEffect, useRef } from 'react';
import { init } from 'ityped';
import Me from '../../img/profilePic.jpg';
import LinkedIn from '../../img/linkedin-logo.png';
import Github from '../../img/github-logo.png';

const Intro = () => {

    const textRef = useRef();

    useEffect(()=>{
        init(textRef.current, { 
            showCursor: true,
            backDelay: 2000, 
            backSpeed: 50,
            typeSpeed: 100,
            strings: ["Software Developer", "Web Developer", "UX/UI Designer"]
        });
    }, []);

    return (
        <div className="flex flex-col md:flex-row px-16 pt-48 pb-32">
            <div className='flex flex-1 items-center justify-center text-center md:text-left'>
                <div className="md:pr-24">
                    <h3 className="font-light">Hello, My name is</h3>
                    <h1 className="py-24 ">Nathan Liu</h1>
                    <h3 className="text-violet-300"><span ref={textRef}> </span></h3>
                    <p className='my-6'>Building beautiful web experiences.</p>
                    <div className='flex justify-center md:justify-start'>            
                        <a href="https://www.linkedin.com/in/nathanliu52/"><img src={LinkedIn} className="w-8 h-8 mr-6"/></a>       
                        <a href="https://github.com/NathanLiu52"><img src={Github} className="w-8 h-8 mr-6" /></a>                       
                    </div>
                </div>
            </div>
            <div className='flex flex-1 items-center justify-center md:justify-start relative'>
                <div className='card bg'></div>
                <div className='card relative'>
                    <img src={Me} alt="Profile Pic" className='w-full h-full object-cover'/>
                </div>

            </div>
        </div>
    )
}

export default Intro