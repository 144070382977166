import React from 'react';
import Product from '../product/Product'
import {products} from "../../data"

const ProductList = () => {
    return (
        <div className='p-16 flex flex-col items-center text-center'>
            <div className='w-full md:w-8/12'>
                <h1 className='mt-8'>My Work</h1>
                <p className='my-8'>
                    Building modern web and mobile applications for clients of all sizes. Specializing in React, take a look at projects I have worked on before. 
                </p>
            </div>
            <div className='flex flex-wrap justify-between w-full'>
                {products.map(item => (
                    <div className='sm:w-1/2 lg:w-1/3 mb-12'>
                        <Product key={item.id} img={item.img}/>
                        <div>
                            <h3 className='text-center sm:text-left ml-2'>{item.title}</h3>
                            <div className='text-center sm:text-left ml-2 my-2 italic font-light'>{item.desc}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProductList