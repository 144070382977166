export const products = [
    {
        id: 1,
        img: require('./img/products/baking.png'),
        title: "Speltacular Baking",
        desc: "Custom website for a small bakery"
    },

    {
        id: 2,
        img: require('./img/products/tiffin.png'),
        title: "Tiffin",
        desc: "Food-creation enabling platform"
    },
    
    {
        id: 3,
        img: require('./img/products/meme.png'),
        title: "Meme Generator",
        desc: "Create memes on the go"
    },
    
    {
        id: 4,
        img: require('./img/products/adidas.png'),
        title: "Adidas Shoe Blog",
        desc: "My favourite sneaker brand"
    },
    
    {
        id: 5,
        img: require('./img/products/pricing.png'),
        title: "Pricing Panel",
        desc: "Enterprise price selection screen"
    },
    
    {
        id: 6,
        img: require('./img/products/scraper.png'),
        title: "GPU Web Scraper",
        desc: "Find a GPU for you"
    },
]