import React from 'react';
import Intro from './components/intro/Intro';
import ProductList from './components/productList/ProductList';
import Contact from './components/contact/Contact';
import './App.css';

const App = () => {
    return(
        <div className="">
            <Intro/>
            <ProductList/>
            <Contact/>
        </div>
    )
}

export default App;