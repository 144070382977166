import React from 'react';

const Product = ({img}) => {
    return (
        <div className='product'>
            <div className='p-browser'>
                <div className='p-circle bg-red-400'></div>
                <div className='p-circle bg-yellow-400'></div>
                <div className='p-circle bg-green-400'></div>
            </div>
            <img src={img} alt="" className='p-img'/>
        </div>
    )
}

export default Product