import React, {useState, useRef} from 'react';
import Phone from '../../img/phone.png';
import Email from '../../img/email.png';
import Address from '../../img/address.png';
import emailjs from '@emailjs/browser';

const Contact = () => {

    const formRef = useRef();
    const [done, setDone] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_orhbsa2', 'template_1klq4he', formRef.current, 'user_FkQZvhpm1tG47NQgkKNCD')
        .then((result) => {
            console.log(result.text);
            setDone(true);
        }, (error) => {
            console.log(error.text);
        });
    }
    return(
        <div className="relative px-16 pt-20 pb-48">
            <div className='flex flex-col md:flex-row'>
                <div className='flex-1'>
                    <h1 className='w-4/5'>Let's Get in Touch!</h1>
                    <div>
                        <div className='flex items-center my-14 font-light w-3/4 '>
                            <img src={Phone} alt="" className='w-8 h-8 mr-6'/> +1 416-436-9440
                        </div>
                        <div className='flex items-center my-14 font-light w-3/4'>
                            <img src={Email} alt="" className='w-8 h-8 mr-6'/> liu_nathan@outlook.com
                        </div>
                        <div className='flex items-center my-14 font-light w-3/4'>
                            <img src={Address} alt="" className='w-8 h-8 mr-6'/> Toronto, Ontario Canada
                        </div>
                    </div>
                </div>
                <div className='flex-1'>
                    <p className="font-light text-left">
                        <b className='font-bold'>How can I help? </b>
                        Always looking to put my skills to use if the right opportunity comes along. 
                    </p>
                    <form ref={formRef} onSubmit={handleSubmit} className='mt-6'>
                        <input type="text" placeholder="Name" name="user_name" className='w-2/3 h-12 border-b my-4 pl-2 border-black' required/>
                        <input type="text" placeholder="Subject" name="user_subject" className='w-2/3 h-12 border-b my-4 pl-2 border-black' required/>
                        <input type="email" placeholder="Email" name="user_email" className='w-2/3 h-12 border-b my-4 pl-2 border-black' required/>
                        <textarea rows="5" placeholder="Message" name="message" className='w-full h-24 border-2 my-8 pl-2 border-black rounded-sm' required></textarea>
                        <input type="submit" value="Submit" className='bg-violet-300 py-2 px-4 font-medium rounded cursor-pointer hover:bg-violet-200'/>
                        {done && <p className="pl-4 inline">Thanks! I will get back to you shortly...</p>}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact